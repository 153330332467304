<template>
	<div>
		<div class="border p-5 mb-5">
			<b-row>
				<b-col class="mb-0">
					<h6>Kampüs</h6>
					<campus-selectbox v-model="campus"/>
					<span>{{ campus }}</span>
				</b-col>
				<b-col class="mb-0">
					<h6>Bina</h6>
					<building-selectbox :campus_id="campus" v-model="building"/>
					<span>{{ building }}</span>
				</b-col>
			</b-row>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<classroom-selectbox :building_id="building" v-model="value1"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<classroom-selectbox :building_id="building" v-model="value2"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Multiple Selected</h6>
					<classroom-selectbox :building_id="building" :multiple="true" v-model="value3"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<classroom-selectbox :building_id="building" v-model="value4" :validateError="value4ValidateError"/>
					<span>{{ value4 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
	import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
	import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";

	export default {
		components: {
			BuildingSelectbox,
			CampusSelectbox,
			ClassroomSelectbox,
		},
		data() {
			return {
				campus: 1,
				building: 5,

				value1: null,
				value2: 105,
				value3: [103, 104, 105],
				value4: 105,
				value4ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.campus = 1
				this.building = 6

				this.value2 = 110
				this.value3 = [109, 110]
				this.value4 = 110
				this.value4ValidateError = 'Error'
			}
		}
	}
</script>

