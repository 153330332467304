<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<h6>Null</h6>
				<prep-classes-selectbox v-model="value1"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Single Selected</h6>
				<prep-classes-selectbox v-model="value2" :academic_year="academiyYear" :module="module" :level="level"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Validate Error</h6>
				<prep-classes-selectbox v-model="value3" :academic_year="academiyYear" :module="module" :level="level" :validateError="value3ValidateError"/>
				<span>{{ value3 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import PrepClassesSelectbox from "@/components/interactive-fields/PrepClassesSelectbox";

	export default {
		components: {
			PrepClassesSelectbox,
		},
		data() {
			return {
				academiyYear: '2017-2018',
				module: 1,
				level: 'A1',

				value1: null,
				value2: 1,
				value3: 1,
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.academiyYear = '2017-2018'
				this.module = 2
				this.level = 'A1'

				this.value2 = 22
				this.value3 = 22
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

