<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<h6>Null</h6>
				<semesters-selectbox v-model="value1"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Single Selected</h6>
				<semesters-selectbox v-model="value2"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Multiple Selected</h6>
				<semesters-selectbox v-model="value3" :multiple="true"/>
				<span>{{ value3 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Validate Error</h6>
				<semesters-selectbox v-model="value4" :validate-error="value4ValidateError"/>
				<span>{{ value4 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

	export default {
		components: {
			SemestersSelectbox,
		},
		data() {
			return {
				value1: null,
				value2: 68,
				value3: [68, 69],
				value4: 68,
				value4ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.value2 = 65
				this.value3 = [65, 66]
				this.value4 = 65
				this.value4ValidateError = 'Error'
			}
		}
	};
</script>

