<template>
	<div>
		<b-row>
			<b-col class="mb-4" md="12">
				<h6>Null</h6>
				<academic-years-selectbox v-model="value1"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col class="mb-4" md="12">
				<h6>Single Selected</h6>
				<academic-years-selectbox v-model="value2"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col class="mb-4" md="12">
				<h6>Multiple Selected</h6>
				<academic-years-selectbox v-model="value3" :multiple="true"/>
				<span>{{ value3 }}</span>
			</b-col>
			<b-col class="mb-4" md="12">
				<h6>Validate Error</h6>
				<academic-years-selectbox v-model="value4" :validate-error="value4ValidateError"/>
				<span>{{ value4 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";

	export default {
		components: {
			AcademicYearsSelectbox
		},
		data() {
			return {
				value1: null,
				value2: "2016-2017",
				value3: ["2016-2017", "2020-2021"],
				value4: "2016-2017",
				value4ValidateError: "Validate Error is Working",
			}
		},
		methods: {
			change() {
				this.value2 = '2021-2022';
				this.value3 = ['2021-2022'];
				this.value4 = '2021-2022';
				this.value4ValidateError = 'Error'
			}
		}
	};
</script>

