<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<h6>Null</h6>
				<yes-no-selectbox v-model="value1"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Single Selected</h6>
				<yes-no-selectbox v-model="value2"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Validate Error</h6>
				<yes-no-selectbox v-model="value3" :validateError="value3ValidateError"/>
				<span>{{ value3 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox";

	export default {
		components: {
			YesNoSelectbox,
		},
		data() {
			return {
				value1: null,
				value2: 'E',
				value3: 'E',
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.value2 = 'H'
				this.value3 = 'H'
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

