<template>
    <div>
        <b-row>
            <b-col md="12" class="mb-4">
                <h6>Null</h6>
                <faculty-selectbox-multiple v-model="value1" :public="true"/>
                <span>{{ value1 }}</span>
            </b-col>
            <b-col md="12" class="mb-4">
                <h6>Single Selected</h6>
                <faculty-selectbox-multiple v-model="value2" :public="true" :multiple="false"/>
                <span>{{ value2 }}</span>
            </b-col>
            <b-col md="12" class="mb-4">
                <h6>Multiple Selected</h6>
                <faculty-selectbox-multiple v-model="value3" :public="true"/>
                <span>{{ value3 }}</span>
            </b-col>
            <b-col md="12" class="mb-4">
                <h6>Validate Error</h6>
                <faculty-selectbox-multiple v-model="value4" :public="true" :validateError="value4ValidateError"/>
                <span>{{ value4 }}</span>
            </b-col>
            <b-col cols="12">
                <b-button variant="primary" @click="change()">
                    {{ $t('change') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import FacultySelectboxMultiple from '@/components/interactive-fields/FacultySelectboxMultiple';

    export default {
        components: {
            FacultySelectboxMultiple
        },
        data() {
            return {
                value1: null,
                value2: '01',
                value3: ['01', '02'],
                value4: ['01', '02'],
                value4ValidateError: 'Validate Error is Working'
            }
        },
        methods: {
            change() {
                this.value2 = '02'
                this.value3 = ['01', '02', '03', '04']
                this.value4 = ['01', '02', '03', '04']
                this.value4ValidateError = 'Error'
            }
        }
    };
</script>

