<template>
	<div>
		<div class="border p-5 mb-5">
			<b-row>
				<b-col cols="6" class="mb-4">
					<div>
						<h6>Fakülte</h6>
						<faculty-selectbox v-model="faculty_code"/>
						<span>{{ faculty_code }}</span>
					</div>
					<div>
						<h6>Bölüm</h6>
						<department-selectbox v-model="department_code" :faculty_code="faculty_code"/>
						<span>{{ department_code }}</span>
					</div>
					<div>
						<h6>Program</h6>
						<program-selectbox v-model="program_code" :faculty_code="faculty_code" :department_code="department_code"/>
						<span>{{ program_code }}</span>
					</div>
				</b-col>
				<b-col cols="6" class="mb-4">
					<h6>Slot Type</h6>
					<slot-type-selectbox v-model="type"/>
					<span>{{ type }}</span>
				</b-col>
			</b-row>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<elective-pool-selectbox v-model="value1" :programCode="program_code" :type="type"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<elective-pool-selectbox v-model="value2" :programCode="program_code" :type="type"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<elective-pool-selectbox v-model="value3" :programCode="program_code" :type="type" :validateError="value3ValidateError"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
	import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
	import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
	import SlotTypeSelectbox from "@/components/interactive-fields/SlotTypeSelectbox";
	import ElectivePoolSelectbox from "@/components/interactive-fields/ElectivePoolSelectbox";

	export default {
		components: {
			FacultySelectbox,
			DepartmentSelectbox,
			ProgramSelectbox,
			SlotTypeSelectbox,
			ElectivePoolSelectbox,
		},
		data() {
			return {
				faculty_code: '08',
				department_code: '0809',
				program_code: '08092201',
				type: 'NK',

				value1: null,
				value2: 1498,
				value3: 1498,
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.faculty_code = '08'
				this.department_code = '0803'
				this.program_code = '08032201'
				this.type = 'G'

				this.value2 = 798
				this.value3 = 798
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

