<template>
    <div>
        <b-row>
            <b-col md="12" class="mb-4">
                <h6>Null</h6>
                <select-date v-model="value1"/>
                <span>{{ value1 }}</span>
            </b-col>
            <b-col md="12" class="mb-4">
                <h6>Single Selected</h6>
                <select-date v-model="value2"/>
                <span>{{ value2 }}</span>
            </b-col>
            <b-col md="12" class="mb-4">
                <h6>Validate Error</h6>
                <select-date v-model="value3" :validation-error="value3ValidateError"/>
                <span>{{ value3 }}</span>
            </b-col>
            <b-col cols="12">
                <b-button variant="primary" @click="change()">
                    {{ $t('change') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import SelectDate from '@/components/interactive-fields/SelectDate';

    export default {
        components: {
            SelectDate
        },
        data() {
            return {
                value1: null,
                value2: '2022-04-23',
                value3: '2022-04-23',
                value3ValidateError: 'Validate Error is Working'
            }
        },
        methods: {
            change() {
                this.value2 = '2023-01-01'
                this.value3 = '2023-01-01'
                this.value3ValidateError = 'Error'
            }
        }
    };
</script>

