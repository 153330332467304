<template>
	<div>
		<div class="border p-5 mb-5">
			<b-row>
				<b-col cols="12" class="mb-4">
					<h6>Fakülte</h6>
					<faculty-selectbox v-model="faculty_code"/>
					<span>{{ faculty_code }}</span>
				</b-col>
			</b-row>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<department-selectbox v-model="value1" :faculty_code="faculty_code" :public="true"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<department-selectbox v-model="value2" :faculty_code="faculty_code" :public="true"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<department-selectbox v-model="value3" :faculty_code="faculty_code" :public="true" :multiple="true"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<department-selectbox v-model="value4" :faculty_code="faculty_code" :public="true" :validateError="value4ValidateError"/>
					<span>{{ value4 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
	import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";

	export default {
		components: {
			FacultySelectbox,
			DepartmentSelectbox,
		},
		data() {
			return {
				faculty_code: '04',

				value1: null,
				value2: '0401',
				value3: ['0401', '0408'],
				value4: '0401',
				value4ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.faculty_code = '01'
				this.value2 = '0110'
				this.value3 = ['0103', '0102']
				this.value4 = '0110'
				this.value4ValidateError = 'Error'
			}
		}
	};
</script>

