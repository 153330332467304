<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<h6>Null</h6>
				<grade-conversions-selectbox v-model="value1"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Single Selected</h6>
				<grade-conversions-selectbox v-model="value2"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Validate Error</h6>
				<grade-conversions-selectbox v-model="value3" :validate-error="value3ValidateError"/>
				<span>{{ value3 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import GradeConversionsSelectbox from "@/components/interactive-fields/GradeConversionsSelectbox";

	export default {
		components: {
			GradeConversionsSelectbox,
		},
		data() {
			return {
				value1: null,
				value2: 1,
				value3: 1,
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.value2 = 2
				this.value3 = 2
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

