<template>
	<div>
		<div class="border p-5 mb-5">
			<b-row>
				<b-col class="mb-0">
					<h6>Faculty</h6>
					<faculty-selectbox v-model="faculty_code"/>
					<span>{{ faculty_code }}</span>
				</b-col>
				<b-col class="mb-0">
					<h6>Departman</h6>
					<department-selectbox v-model="department_code" :faculty_code="faculty_code"/>
					<span>{{ department_code }}</span>
				</b-col>
			</b-row>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<program-selectbox-multiple v-model="value1" :faculty_code="faculty_code" :department_code="department_code"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<program-selectbox-multiple v-model="value2" :faculty_code="faculty_code" :department_code="department_code"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Multiple Selected</h6>
					<program-selectbox-multiple v-model="value3" :faculty_code="faculty_code" :department_code="department_code" :multiple="true"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<program-selectbox-multiple v-model="value4" :faculty_code="faculty_code" :department_code="department_code" :validateError="value4ValidateError"/>
					<span>{{ value4 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
	import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
	import ProgramSelectboxMultiple from "@/components/interactive-fields/ProgramSelectboxMultiple";

	export default {
		components: {
			FacultySelectbox,
			DepartmentSelectbox,
			ProgramSelectboxMultiple,
		},
		data() {
			return {
				faculty_code: '04',
				department_code: '0401',

				value1: null,
				value2: '04018102',
				value3: ['04018102', '04018103'],
				value4: '04018102',
				value4ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.faculty_code = '03'
				this.department_code = '0306'

				this.value2 = '03062102'
				this.value3 = ['03062102','03068101']
				this.value4 = '03062102'
				this.value4ValidateError = 'Error'
			}
		}
	}
</script>

